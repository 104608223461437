import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import ilustrationGreen from "../images/ilustration-green.svg"
import ilustrationBlack from "../images/ilustration-black.svg"
import * as vars from "../styles/vars"
import { gsap, Power0 } from "gsap"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
/* eslint-disable */
gsap.registerPlugin(MotionPathPlugin)

const HeroHome = () => {
  const illustration = React.createRef()
  let [ilustrationTween, setIlustrationTween] = useState(null)

  useEffect(() => {
    setIlustrationTween(
      gsap.to(illustration.current, {
        duration: 1.5,
        ease: Power0.easeNone,
        paused: true,
        repeat: -1,
        motionPath: {
          path: [
            { right: "8px", top: "0px" },
            { right: "8px", top: "-8px" },
            { right: "-8px", top: "-8px" },
            { right: "-8px", top: "8px" },
            { right: "8px", top: "0px" },
          ],
        },
      })
    )
    // eslint-disable-next-line
  }, [])

  const onMouseEnterHandler = () => {
    ilustrationTween.play()
  }
  const onMouseLeaveHandler = () => {
    ilustrationTween.pause()
  }

  return (
    <HeroSection className="hero">
      <div className="container">
        <div className="info">
          <h1>
            Hey! <span>Soy Ainoa</span>.
            <br />
            Periodista y
            <br />
            diseñadora gráfica <span>&</span> web
          </h1>
          <p>
            Bienvenido/a a mi <strike>web</strike> portfolio.
          </p>
        </div>
        <div
          className="ilustration"
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          <img
            src={ilustrationBlack}
            alt="silueta ilustración"
            className="front"
          />
          <img
            src={ilustrationGreen}
            alt="silueta ilustración"
            className="back"
            ref={illustration}
          />
        </div>
      </div>
    </HeroSection>
  )
}

export default HeroHome

const HeroSection = styled.section`
  position: relative;
  .container {
    height: 100vh;
  }
  .info {
    h1 {
      margin-bottom: 65px;
      ${vars.sm} {
        margin-bottom: 40px;
      }
      ${vars.md} {
        margin-bottom: 40px;
      }
      ${vars.xxl} {
        margin-bottom: 70px;
      }
    }
  }
  .ilustration {
    display: none;
    position: relative;
    ${vars.md} {
      display: block;
    }

    img {
      position: relative;
      right: 0;
      height: 55vh;
      ${vars.md} {
        max-height: 320px;
      }
      ${vars.lg} {
        max-height: 580px;
      }
      ${vars.xxl} {
        max-height: none;
      }
      &.back {
        position: absolute;
        right: 8px;
        top: 0px;
        z-index: 1;
      }
      &.front {
        z-index: 2;
      }
    }
  }
`
