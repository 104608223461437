import React, { useState } from "react"
import styled from "@emotion/styled"
import * as vars from "../styles/vars"
import useProyectos from "../hooks/useProyectosHome"
import ProyectoPreview from "./proyectoPreview"
import { gsap, Power0 } from "gsap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const onMouseEnterHandler = () => {
  const getRandom = (min, max) => Math.random() * (max - min) + min
  const span = document.querySelectorAll(".home h3 span")
  document.querySelector(".home h3").style.zIndex = "-1"
  span.forEach(element => {
    var offsetX = getRandom(-2000, 2000)
    var offsetY = getRandom(-2000, 2000)
    gsap.to(element, {
      duration: 0.6,
      repeat: 0,
      ease: Power0.easeNone,
      translateX: offsetX,
      translateY: offsetY,
      zIndex: -1,
    })
  })
}

const onMouseLeaveHandler = () => {
  document.querySelector(".home h3").style.zIndex = "2"
  const span = document.querySelectorAll(".home h3 span")
  span.forEach(element => {
    gsap.to(element, {
      duration: 0.6,
      repeat: 0,
      ease: Power0.easeNone,
      translateX: 0,
      translateY: 0,
      opacity: 1,
    })
  })
}

const ProjectsBox = () => {
  const resultado = useProyectos()
  const [proyectos] = useState(resultado)

  return (
    <>
      <ProjectSingle
        className="project-box home"
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        <h3>
          <span>E</span>
          <span>S</span>
          <span>T</span>
          <span>O</span> <span>E</span>
          <span>S</span> <span>L</span>
          <span>O</span> <span>Q</span>
          <span>U</span>
          <span>E</span> <span>H</span>
          <span>A</span>
          <span>G</span>
          <span>O</span>
        </h3>
        {proyectos.map(proyecto => (
          <ProyectoPreview key={proyecto.id} proyecto={proyecto} />
        ))}
        <h4>esto es lo que hago</h4>
      </ProjectSingle>
      <ViewMore className="view-more">
        <AniLink cover to="page-3" bg="#2CFFB9" to="/proyectos">
          aquí puedes ver más
        </AniLink>
      </ViewMore>
    </>
  )
}

export default ProjectsBox

const ProjectSingle = styled.div`
  display: block;
  position: relative;
  margin-bottom: 25px;
  width: 100%;
  h4 {
    font-family: ${vars.barlow};
    position: relative;
    font-family: ${vars.barlow};
    text-transform: uppercase;
    font-size: 1.563rem;
    line-height: 31px;
    color: ${vars.green};
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${vars.green};
    ${vars.sm} {
      font-size: 3.188rem;
      line-height: 61px;
    }
    ${vars.md} {
      display: none;
    }
  }
  ${vars.md} {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    &:after {
      display: none;
    }
    &.archive {
      flex-wrap: wrap;
      .project {
        margin-bottom: 30px;
      }
    }
  }
  ${vars.lg} {
    margin-bottom: 35px;
  }
  ${vars.xl} {
    margin-bottom: 50px;
  }
  ${vars.xxl} {
    margin-bottom: 75px;
  }
  h3 {
    position: absolute;
    font-family: ${vars.barlow};
    text-transform: uppercase;
    font-size: 8.75rem;
    text-align: center;
    z-index: 2;
    color: ${vars.green};
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${vars.green};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: none;
    z-index: 2;
    transition: z-index 0.6s ease;
    ${vars.md} {
      display: block;
      font-size: 4.7rem;
    }
    ${vars.lg} {
      font-size: 4.8rem;
    }
    ${vars.xl} {
      font-size: 5.6rem;
    }
    ${vars.xxl} {
      font-size: 7.6rem;
    }
    span {
      display: inline-block;
    }
  }
  .project {
    height: auto;
    position: relative;
    margin-bottom: 25px;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    ${vars.md} {
      flex: 0 0 calc(50% - 15px);
      margin-bottom: 0;
    }
    .info {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      border: 7px solid ${vars.green};
      background-color: rgba(8, 8, 8, 0.5);
      top: 0;
      left: 0;
      transition: all 0.3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: white;
        font-family: ${vars.barlow};
        font-weight: 300;
      }
    }
    img {
      width: 100%;
      height: auto;
      position: relative;
    }
    &:hover {
      .info {
        opacity: 1;
        z-index: 2;
      }
    }
  }
`

const ViewMore = styled.div`
  font-family: ${vars.barlow};
  font-weight: 300;
  font-size: 0.938rem;
  line-height: 18px;
  position: relative;
  display: inline-block;
  ${vars.sm} {
    font-size: 1.25rem;
    line-height: 24px;
  }
  ${vars.md} {
    font-size: 0.938rem;
    line-height: 18px;
  }
  ${vars.lg} {
    font-size: 1.125rem;
    line-height: 22px;
  }
  ${vars.xl} {
    font-size: 1.563rem;
    line-height: 30px;
  }
  ${vars.xxl} {
    font-size: 1.875rem;
    line-height: 36px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -8px;
    background: ${vars.green};
  }
  ${vars.hoverColor}
`
