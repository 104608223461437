import React from "react"
import Layout from "../components/layout"
import ListadoProyectos from "../components/listadoProyectos"
import HeroHome from "../components/heroHome"
import SEO from "../components/seo"
const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Ainoa Molina"
        description="Hey! Soy Ainoa. Periodista y diseñadora gráfica & web. Bienvenido/a a mi web portfolio."
      />
      <HeroHome />
      <ListadoProyectos />
    </Layout>
  )
}

export default IndexPage
