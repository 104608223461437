import React from "react"
import styled from "@emotion/styled"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import * as vars from "../styles/vars"
const Links = () => {
  return (
    <LinksBlock className="links">
      <div className="link">
        <LinkBlock className="project-section">
          <h2>
            <AniLink cover bg="#2CFFB9" to="/proyectos-disenio">
              proyectos & diseño
            </AniLink>
          </h2>
        </LinkBlock>
      </div>
      <div className="link">
        <LinkBlock className="project-section">
          <h2>
            <AniLink cover bg="#2CFFB9" to="/arte-ideas">
              arte & ideas
            </AniLink>
          </h2>
        </LinkBlock>
      </div>
    </LinksBlock>
  )
}

export default Links

const LinksBlock = styled.div`
  flex: 0 0 100%;
  &:before {
    content: "¿Qué quieres ver?";
    color: ${vars.green};
    font-size: 0.938rem;
    line-height: 18px;
    font-weight: 700;
    font-family: ${vars.barlow};
    position: absolute;
    margin-top: -35px;
    text-transform: uppercase;
  }
  padding-bottom: 50px;
  ${vars.sm} {
    padding-bottom: 75px;
  }
  ${vars.md} {
    padding-bottom: 75px;
    &:before {
      display: none;
    }
  }
  ${vars.lg} {
    padding-bottom: 70px;
  }
  ${vars.xl} {
    padding-bottom: 100px;
  }
  ${vars.xxl} {
    padding-bottom: 150px;
  }
`
const LinkBlock = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    background: ${vars.green};
  }
  h2 {
    font-family: ${vars.barlow};
    font-weight: 300;
    font-size: 1.625rem;
    line-height: 31px;
    ${vars.hoverColor}
    ${vars.sm} {
      font-size: 3.125rem;
      line-height: 60px;
    }
    ${vars.lg} {
      font-size: 4.375rem;
      line-height: 84px;
    }
    ${vars.xl} {
      font-size: 3.75rem;
      line-height: 84px;
    }
    ${vars.xxl} {
      font-size: 8.125rem;
      line-height: 156px;
    }
  }
`
