import React from 'react'
import styled from '@emotion/styled'
import * as vars from '../styles/vars'

const ProjectDescription = () => {
    return (
        <Description className="description">
            <p>
                Aquí tienes una pequeña muestra de algunos de mis trabajos.
                Los que le enseñaría a cualquiera que me pregunte eso
                de “ y tú, <strong>¿qué haces?</strong> ”
            </p>
        </Description>
    )
}

export default ProjectDescription

const Description = styled.div`
        padding-bottom: 25px;
        ${vars.sm}{
            padding-bottom: 50px;
        }
        ${vars.lg}{
            padding-bottom: 70px;
        }
        ${vars.xl}{
            padding-bottom: 50px;
        }
        ${vars.xxl}{
            padding-bottom: 150px;
        }
        p{
            font-family: ${vars.barlow};
            font-weight: 300;
        }
`