import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"

const ProyectoPreview = ({ proyecto }) => {
  const { imagen_destacada, id, slug, categoria_vista, alt_text } = proyecto

  return (
    <>
      <div key={id} className="project">
        <AniLink cover bg="#2CFFB9" to={`/${slug}`}>
          <Img fluid={imagen_destacada.childImageSharp.fluid} alt={alt_text} />
          <div className="info">
            <p dangerouslySetInnerHTML={{ __html: categoria_vista }}></p>
          </div>
        </AniLink>
      </div>
    </>
  )
}

export default ProyectoPreview
