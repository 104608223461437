import { useStaticQuery, graphql } from "gatsby"

const useProyectos = () => {
    const datos = useStaticQuery(
        graphql`
            {
                allWordpressWpAiProyectos(filter: {acf: {featured_cover: {eq: true}}}) {
                   
                    nodes {
                        title
                        ai_categorias
                        slug
                        featured_media {
                            localFile {
                                childImageSharp {
                                    fluid( maxWidth: 800 ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        wordpress_id
                        content
                        acf {
                            category_project
                            description_project
                            featured_cover
                            images_project {
                                image_project{
                                    id,
                                    localFile {
                                        childImageSharp {
                                            fluid( maxWidth: 800 ) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        `
    )
    // console.log(datos);
    return datos.allWordpressWpAiProyectos.nodes.map( proyecto => ({
        titulo: proyecto.title,
        categoria_filtro: proyecto.ai_categorias,
        imagen_destacada: proyecto.featured_media.localFile,
        id: proyecto.wordpress_id,
        contenido: proyecto.content,
        categoria_vista: proyecto.acf.category_project,
        descripcion: proyecto.acf.description_project,
        enPortada: proyecto.acf.featured_cover,
        imagenes: proyecto.acf.images_project,
        slug: proyecto.slug,
    }))
    
}

export default useProyectos